import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

let dummyResponse = {
  data: {
    isSuccess: false,
  },
};
let dummyResponse400;
const setErrorMessage = (message) => {
  dummyResponse400 = {
    data: {
      isSuccess: false,
      message: message,
    },
  };
  return dummyResponse400;
};

export const getRequest = async (url, active = "") => {
  const logIntoken = localStorage.getItem("loginToken");

  const apiConfig = {
    headers: {
      Authorization: `Bearer ${logIntoken}`,
    },
  };

  let baseurl = process.env.REACT_APP_BASE_URL;
  let activeUrl = JSON.parse(process.env.REACT_APP_BASE_URL_LIST);
  if (active) {
    activeUrl.services.map((item) => {
      if (item.code === active) {
        baseurl = item.url;
      }
    });
  }

  try {
    let response = await axios.get(
      `${baseurl}${url}`,
      apiConfig ? apiConfig : {}
    );
    if (response && response?.data?.isSuccess) {
      return response;
    } else {
      const error = response?.data?.message
        ? response?.data?.message
        : "Something Went Wrong";
      toast.error(error);
      return dummyResponse;
    }
  } catch (error) {
    if (error.response?.status === 400) {
      setErrorMessage(
        error?.response?.data?.errorMessage ||
          error?.response?.data?.error ||
          "Something Went Wrong"
      );
      // const errorMessage = error.code == "ERR_NETWORK" ? error.message : "Something Went Wrong";
      // toast.error(errorMessage);
      // setErrorMessage(errorMessage)
      return dummyResponse400;
    }

    if (
      error.response &&
      (error.response?.status === 404 || error.response?.status === 500)
    ) {
      toast.error("Something Went Wrong");
      return dummyResponse;
    }
    if (axios.isCancel(error)) {
      return dummyResponse;
    } else {
      const errorMessage =
        error.code == "ERR_NETWORK" ? error.message : "Something Went Wrong";
      // toast.error(errorMessage);
      return dummyResponse;
    }
  }
};

export const postRequest = async (
  url,
  payload,
  active = "",
  handleFileProgress = ""
) => {
  const logIntoken = localStorage.getItem("loginToken");

  const apiConfig = {
    headers: {
      Authorization: `Bearer ${logIntoken}`,
    },
  };
  let baseurl = process.env.REACT_APP_BASE_URL;
  let activeUrl = JSON.parse(process.env.REACT_APP_BASE_URL_LIST);
  activeUrl.services.map((item) => {
    if (item.code === active) {
      baseurl = item.url;
    }
  });
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const offsetInMinutes = new Date().getTimezoneOffset();

  try {
    let response = await axios.post(
      `${baseurl}${url}`,
      {
        ...payload,
        timeZone: timeZone,
        timeZoneMinuteDifference: offsetInMinutes,
      },
      {
        headers: {
          Authorization: `Bearer ${logIntoken}`,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (handleFileProgress) {
            handleFileProgress(percentCompleted);
          }
        },
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (handleFileProgress) {
            handleFileProgress(percentCompleted);
          }
        },
      }
    );

    if (
      (response && response?.data.isSuccess) ||
      response?.data.status === 200
    ) {
      return response;
    } else {
      const error = response?.data?.message
        ? response?.data?.message
        : "Something Went Wrong";
      toast.error(error);
      return dummyResponse;
    }
  } catch (error) {
    if (error.response?.status === 400) {
      setErrorMessage(
        error?.response?.data?.errorMessage ||
          error?.response?.data?.error ||
          "Something Went Wrong"
      );
      return dummyResponse400;
    }
    if (axios.isCancel(error)) {
      return dummyResponse;
    }
    if (
      error.response &&
      (error.response?.status === 404 || error.response?.status === 500)
    ) {
      toast.error("Data Not Found");
      return dummyResponse;
    } else {
      const errorMessage =
        error.code == "ERR_NETWORK" ? error.message : "Something Went Wrong";
      toast.error(errorMessage);
      return dummyResponse;
    }
  }
};

export const putRequest = async (url, payload, active = "") => {
  const logIntoken = localStorage.getItem("loginToken");

  const apiConfig = {
    headers: {
      Authorization: `Bearer ${logIntoken}`,
    },
  };
  let baseurl = process.env.REACT_APP_BASE_URL;
  let activeUrl = JSON.parse(process.env.REACT_APP_BASE_URL_LIST);
  activeUrl.services.map((item) => {
    if (item.code === active) {
      baseurl = item.url;
    }
  });
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const offsetInMinutes = new Date().getTimezoneOffset();
  try {
    let response = await axios.put(
      `${baseurl}${url}`,
      {
        ...payload,
        timeZone: timeZone,
        timeZoneMinuteDifference: offsetInMinutes,
      },
      apiConfig ? apiConfig : {}
    );
    if (response && response?.data.isSuccess) {
      return response;
    } else {
      const error = response?.data?.message
        ? response?.data?.message
        : "Something Went Wrong";
      toast.error(error);
      return dummyResponse;
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      return dummyResponse;
    }

    if (error.response?.status === 400) {
      setErrorMessage(error?.response?.data?.errorMessage);

      return dummyResponse400;
    }
    if (
      error.response &&
      (error.response?.status === 404 || error.response?.status === 500)
    ) {
      toast.error("Data Not Found");
      return dummyResponse;
    } else {
      const errorMessage =
        error.code == "ERR_NETWORK" ? error.message : "Something Went Wrong";
      toast.error(errorMessage);
      return dummyResponse;
    }
  }
};

export const uploadFileUpdateRequest = async (url, active = "") => {
  const logIntoken = localStorage.getItem("loginToken");

  const apiConfig = {
    headers: {
      Authorization: `Bearer ${logIntoken}`,
    },
  };
  let baseurl = process.env.REACT_APP_BASE_URL;
  let activeUrl = JSON.parse(process.env.REACT_APP_BASE_URL_LIST);
  activeUrl.services.map((item) => {
    if (item.code === active) {
      baseurl = item.url;
    }
  });
  try {
    let response = await axios.put(`${baseurl}${url}`, {}, apiConfig);
    if (response && response?.data.isSuccess) {
      return response;
    } else {
      const error = response?.data?.message
        ? response?.data?.message
        : "Something Went Wrong";
      toast.error(error);
      return dummyResponse;
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      return dummyResponse;
    }
    if (error.response?.status === 400) {
      setErrorMessage(error?.response?.data?.errorMessage);
      return dummyResponse400;
    }

    if (
      error.response &&
      (error.response?.status === 404 ||
        error.response?.status === 400 ||
        error.response?.status === 500)
    ) {
      toast.error("Data Not Found");
      return dummyResponse;
    } else {
      const errorMessage =
        error.code == "ERR_NETWORK" ? error.message : "Something Went Wrong";
      toast.error(errorMessage);
      return dummyResponse;
    }
  }
};

export const postRequestForUpload = async (
  url,
  payload,
  active = "",
  handleFileProgress = ""
) => {
  const logIntoken = localStorage.getItem("loginToken");

  const apiConfig = {
    headers: {
      Authorization: `Bearer ${logIntoken}`,
    },
  };
  let baseurl = process.env.REACT_APP_BASE_URL;
  let activeUrl = JSON.parse(process.env.REACT_APP_BASE_URL_LIST);
  activeUrl.services.map((item) => {
    if (item.code === active) {
      baseurl = item.url;
    }
  });
  // const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  // const offsetInMinutes = new Date().getTimezoneOffset();

  try {
    let response = await axios.post(`${baseurl}${url}`, payload, {
      headers: {
        Authorization: `Bearer ${logIntoken}`,
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (handleFileProgress) {
          handleFileProgress(percentCompleted);
        }
      },
      onDownloadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (handleFileProgress) {
          handleFileProgress(percentCompleted);
        }
      },
    });

    if (response && response?.data.isSuccess) {
      return response;
    } else {
      const error = response?.data?.message
        ? response?.data?.message
        : "Something Went Wrong";
      toast.error(error);
      return dummyResponse;
    }
  } catch (error) {
    if (error.response?.status === 400) {
      setErrorMessage(error?.response?.data?.errorMessage);
      return dummyResponse400;
    }
    if (axios.isCancel(error)) {
      return dummyResponse;
    }
    if (
      error.response &&
      (error.response?.status === 404 || error.response?.status === 500)
    ) {
      toast.error("Data Not Found");
      return dummyResponse;
    } else {
      const errorMessage =
        error.code == "ERR_NETWORK" ? error.message : "Something Went Wrong";
      toast.error(errorMessage);
      return dummyResponse;
    }
  }
};
