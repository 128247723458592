import React from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import "./UserDetails.scss"
import { Avatar, Button, Grid, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { saveBusinessName, saveFileClaimWithoutJob } from '../../pages/Login/LoginSlice';


export default function UserDetails() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOnClick = () => {
    dispatch(saveFileClaimWithoutJob(0))
    dispatch(saveBusinessName(''))

    handleClose()
    window.localStorage.removeItem("loginToken")
    window.localStorage.removeItem("email")
    window.localStorage.removeItem("servicerId")
    window.localStorage.removeItem("roleType")
    window.localStorage.removeItem("userName")
    window.localStorage.removeItem("organisationType")
    window.localStorage.removeItem("organisationName")
    window.localStorage.removeItem("businessName")
    window.localStorage.clear()

    navigate('/')
    // alert("sdfsdf")

  }
  return (
    <Grid xs={12} className='UserDetails-Container'>
      {/* <img src="/Pic.png" alt='user-pic' /> */}
      <Avatar sx={{ bgcolor: "#E56A54", marginRight: "10px" }}>{window.localStorage.getItem("userName") === 'null null' ? "" : window.localStorage.getItem("userName")[0].toUpperCase()}</Avatar>

      {/* <span>Rowan Elms</span> */}
      <Button color='primary' disableTouchRipple sx={{
        padding: 0, margin: 0, "&.MuiButton-root": {
          minWidth: 0, cursor: "pointer"
        }
      }} aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <KeyboardArrowDownIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleOnClick}>Logout</MenuItem>
      </Menu>
    </Grid>
  )
}
