import { Button, Grid, Typography } from "@mui/material"
import mhitLogoIcon from "../../assets/mhit-icons/methodhublogo.svg"
import "./ServicerNavBar.scss"
// import { menuItems } from "../../components/ServicerNavBar/ServicerNavBarMenuItems"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
// import { Image } from "@mui/icons-material"
import CloseIcon from '@mui/icons-material/Close';
// import { useSelector } from "react-redux"
import MenuItems from "../ServicerNavBar/ServicerNavBarMenuItems"

const ServicerNavBar = ({ setHamburgerMenu, hamburgerMenu }) => {
  const navigate = useNavigate()
  const location = useLocation();
  const [clickedButton, setClickedButton] = useState(location.state?.activeIndex);
  const [keyCLockToken, setkeyCloakLoginToken] = useState(localStorage?.getItem("keyCloakLoginToken"))
  // const getFileClaimWithoutJobFromRedux = useSelector((state) => {
  //   return Number(state?.Login?.fileClaimWithoutJob)
  // })
  
useEffect(() => {
    menuItems.map((item) => {
      if (item.path == location.pathname) {
        setClickedButton(item.key)
      }
    })
  }, [location])

  const handleClose = () => {
    setHamburgerMenu(false)
  }

  const handleOnClick = (key, path, url) => {
    if (url) {
      window.open(`${process.env.REACT_APP_CLAIMS_URL}/dashboard?access_token=${keyCLockToken}`)
    }
    setClickedButton(key);
    if (path) {
      navigate(path)
    }
    handleClose()
  }
 
const menuItems =MenuItems()

  return (
    <>
      <Grid xs={12} spacing={0} className="main-servicerNavBar" style={{ position: "fixed", boxSizing: 'border-box', minHeight: "100vh" }} >
        <Grid xs={2} container spacing={0} className="main-contianer-servicerNavBar" style={{ position: "fixed", top: "0", left: "0", maxHeight: "2vw", width: "80%" }}>
          <Grid className="close_icon" style={{ display: "flex", justifyContent: "flex-end", padding: "2vw 3vw", minWidth: "100%" }}>
            <Grid style={{ alignSelf: "flex-end", alignContent: "flex-end" }} >
              <CloseIcon style={{ height: "4vw", width: "4vw" }} onClick={() => { handleClose() }} />
            </Grid>
          </Grid>
          <Grid xs={12} className="main-container-avatar" style={{ display: "flex", justifyContent: "center" }}>
            <div className="image-container" >
              <img src={mhitLogoIcon} alt="icon" className="serviceAvenger-icon-image" />
            </div>
          </Grid>
          {
            menuItems.map((item, key) => (
              !!item?.show &&(
                <>
                <Grid item xs={12} className="servicer-name" key={item.key} >
                  {
                    <Button className="servicer-name-button" 
                      disableElevation
                      disableFocusRipple
                      disableTouchRipple
                      sx={{
                        "&.MuiButtonBase-root": {
                          padding: "8px 2px 8px 5px "
                        },
                        width: "15vw",
                        wordBreak: "break-word",
                        "& .MuiTypography-root ": {
                          fontSize: "13px",
                        },
                        "&.MuiButton-root": {
                          display: "flex",
                          justifyContent: "flex-start",
                          fontSize: "300px",
                        },
                        '@media  (max-width:800px)': {
                          width: "16px",
                          fontSize: "14px",
                          padding: "4px 12px",
                        },
                        '@media (max-width:1023)': {
                          fontSize: "9.7px",
                          padding: "0 2px",
                          border: "1px solid red"
                        },
                        '@media (min-width: 1024px) and (max-width: 1282px)': {
                          fontSize: "14px",
                          padding: "5px 5px",
                        },
                      }}
                      onClick={() => handleOnClick(item.key, item?.path, item?.isUrl)}>{ <img src={item.Icon[0]} alt="icon" />}
                      <span className="servicer-navbar-title" style={{ marginLeft: "5px" }}></span><Typography sx={{ textTransform: "none",
                          fontWeight:clickedButton && clickedButton === item.key ?900:200,
                          color:clickedButton && clickedButton === item.key ?"#1A4277":"#5C678F"
                          }} className="servicer-navbar-title" >{item.title}</Typography>
                    </Button>
                  }
                </Grid>
                </>
              ) 
            ))
          }
        </Grid>
      </Grid>
    </>
  )
}
export default ServicerNavBar