import React from 'react'
// import SearchBar from '../SearchBar/SearchBar'
import UserDetails from '../UserDetails/UserDetails'
// import Navbar from '../Navbar/Navbar'
import "./HeaderSidebar.scss"
// import ServicerName from "../Header/ServicerName"
import { useParams } from 'react-router-dom'
import { Box, Grid, Paper, Typography } from '@mui/material'
import { getRequest } from '../../axios/axios'
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from 'react-redux'
import { saveFileClaimWithoutJob, saveBusinessName } from '../../pages/Login/LoginSlice'

export default function HeaderSidebar({ setHamburgerMenu }) {
  const { id } = useParams()
  const dispatch = useDispatch()

  React.useEffect(() => {
    const storeIsdCode = async () => {
      const response = await getRequest(`/api/getServicerDetail/my`, "sdProvider")
      if (response?.data?.isSuccess) {
        window.localStorage.setItem("isdCode", response?.data?.data?.account?.isdCode)
        window.localStorage.setItem("phoneRegionCode", response?.data?.data?.dispatchMethod?.phoneRegionCode)
        window.localStorage.setItem("fileClaimWithoutJob", response?.data?.data?.account?.fileClaimWithoutJob)
        window.localStorage.setItem("businessName", response?.data?.data?.account?.fileClaimWithoutJob)
        dispatch(saveFileClaimWithoutJob(localStorage?.getItem("fileClaimWithoutJob")))
        dispatch(saveBusinessName(response?.data?.data?.account?.businessName))
      }
    }
    storeIsdCode()
  }, [])

  const getBusinessNameFromRedux = useSelector((state) => {
    return state?.Login?.businessName
  })

  return (
    <Grid xs={12} className='HeaderSidebar-container'
    // sx={{ position: "relative", width: "100%",border:'1px solid red',boxSizing:'border-box'}}
    >
      <Grid xs={12} component={Paper} elevation={3} className='HeaderSidebar' sx={{ width: "100%" }}>

        <Grid container xs={7} style={{ display: 'flex', color: "#1E384B", alignItems: "center" }}>
          <Box
            item
            component={Grid}
            xs={2}
            style={{ marginLeft: "-16px" }}
            display={{ xs: "block", sm: "none", md: "none" }}
          >
            <MenuIcon onClick={() => { setHamburgerMenu(true) }} />
          </Box>
          <Grid item xs={10} sm={12} style={{ marginLeft: "8px", }} >
            <Typography variant='h6' color='#1E384B' className='text-style'>{getBusinessNameFromRedux === 'undefined' ? "" : getBusinessNameFromRedux}</Typography>
          </Grid>
        </Grid>
        {/* <img src='/Logo.png' alt='logo' className='logo'></img> */}
        <div className='search-user'>
          {/* {id ? <ServicerName /> : null} */}
          {/* <div className='search-bar'><SearchBar /></div> */}
          <UserDetails />
        </div>
      </Grid>
    </Grid>
  )
}
